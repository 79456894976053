import { customersList } from "./CustomersList";
import { customer } from "./Customer";
import { projects } from "./Projects";
import { admins } from "./Admins";
import { projectsList } from "./ProjectsList";
import { project } from "./Project";
import { orders } from "./Orders";
import { products } from "./Products";
import { orderSummary } from "./OrderSummary";
import { customerWallet } from "./CustomerWallet";
import { currency } from "./Currency";
import { country } from "./Country";
import { states } from "./States";
import { phoneCountryInput } from "./PhonCountryInput";
import { walletLedger } from "./WalletLedger";
import { masterProjects } from "./MasterProjects";
import { products2 } from "./Products2";
import { customOrders } from "./CustomOrders";
import { customOrder } from "./CustomOrder";
import { requestForms } from "./RequestForms";
import { trelloBoards } from "./TrelloBoards";
import { trelloCards } from "./TrelloCards";
import { trelloCardInfo } from "./TrelloCardInfo";
import { trelloMembersList } from "./TrelloMembersList";
import { trelloList } from "./TrelloList";
import { trelloAttachmentList } from "./TrelloAttachmentList";
import { trelloCommentList } from "./TrelloCommentList";
import { trelloCheckList } from "./TrelloCheckList";
import { trelloNewList } from "./TrelloNewList";
import { trelloAllMembersList } from "./TrelloAllMembersList";
import { trelloSearchCardList } from "./TrelloSearchCardList";
import { projectFolderpath } from "./projectFolderpath";
import { partnersList } from "./PartnersList";
import { partnerDetails } from "./PartnerDetails";
import { partnerDetailsSingle } from "./PartnerDetailsSingle";
import {partnerLocations} from './PartnerLocations';
import { units } from "./Units";
import {partnersComissionList} from './PartnersComissionList';
import {partnersComissionWithdrawalList} from './PartnersComissionWithdrawalList';
import {transectionList} from './TransectionList';
import {projectsCatlog} from './projectsCatlog';
import {paymentLinkList} from './paymentLinkList';
import {gatewayList} from './GatewayList'
import {downlineAdmins} from './downlineAdmins'
import {invoicesList} from './InvoicesList'
import {partnerleadlist} from './Partnerleadlist'
import {projectAttachementCategories} from './projectAttachementCategories';
import {projectAttachementSubCategories} from './projectAttachementSubCategories';
import { crmCustomfieldList } from "./crmCustomfieldList";
import {resetState} from "./ResetState"
import { customerLinkRequestList } from "./CustomerLinkRequestList";
import { BrowserFileList } from "./BrowserFileList";
import { browserFileActionLogsList } from "./browserFileActionLogsList";
import { sitesList } from "./SitesList";
import { projectDetail } from "./projectDetail";
import { interiorDesignList } from "./interiorDesignList";


const actionCreators = {
  units,
  customersList: customersList,
  customer: customer,
  projects: projects,
  admins: admins,
  downlineAdmins: downlineAdmins,
  projectsList: projectsList,
  project: project,
  orders: orders,
  products: products,
  orderSummary: orderSummary,
  customerWallet: customerWallet,
  currency,
  country,
  states,
  phoneCountryInput,
  walletLedger,
  masterProjects,
  products2,
  customOrders,
  customOrder,
  requestForms,
  trelloBoards,
  trelloCards,
  trelloCardInfo,
  trelloMembersList,
  trelloList,
  trelloAttachmentList,
  trelloCommentList,
  trelloCheckList,
  trelloNewList,
  trelloAllMembersList,
  trelloSearchCardList,
  projectFolderpath,
  partnersList,
  partnerDetails,
  partnerDetailsSingle,
  partnerLocations,
  partnersComissionList,
  partnersComissionWithdrawalList,
  transectionList,
  projectsCatlog,
  paymentLinkList,
  gatewayList,
  invoicesList,
  partnerleadlist,
  projectAttachementCategories,
  projectAttachementSubCategories,
  crmCustomfieldList,
  resetState,
  customerLinkRequestList,
  BrowserFileList,
  browserFileActionLogsList,
  sitesList,
  projectDetail,
  interiorDesignList,
};
export default actionCreators
