import React, { useEffect, useState } from "react";
import {
  ActionDiv,
  FDiv,
  FHeading,
  FItem,
} from "./interioriDesignListingStyle";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  HStack,
  Radio,
} from "@chakra-ui/react";
import { Input } from "antd";

export default function InterioriDesignFilter(props) {
  const [formData, setFormData] = useState({ imagetype: "any" });
  

  useEffect(() => {
    props.setFilterData(formData);
  }, [formData]);

  const handleSearchForm = (event) => {
    setFormData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <>
      <FHeading>
        Filters
        <ActionDiv>
          <span
            style={{
              color: "#7a7ae6",
              fontSize: "16px",
              cursor: "pointer",
              padding: "0 5px",
            }}
            onClick={() => {
              setFormData({});
              props.setFilterClear(1);
            }}
          >
            Clear
          </span>
          <span
            style={{
              color: "#7a7ae6",
              fontSize: "16px",
              cursor: "pointer",
              padding: "0 5px",
            }}
            onClick={() => {
              // if (formData?.projectID && !params.get(`projectID`)) {
              //   params.set("projectID", formData?.projectID);
              //   history.push({ search: params.toString() });
              // }

              // Trigger search directly after setting form data
              const searchQuery = Object.entries(formData)
                .map(([key, value]) => `${key}=${value}`)
                .join("&");

              // props.setSearchData(true);
              // props.setCurrent({ page: 1, size: 10 }); // Reset pagination on search

              // Trigger the API call for search
              props.interiorDesignList(
                `limit=10&offset=0&page=1&${searchQuery}`
              );
            }}
          >
            Search
          </span>
        </ActionDiv>
      </FHeading>
      <FDiv>
        <Accordion allowToggle defaultIndex={[0]}>
          <AccordionItem>
            <h2>
              <AccordionButton
                style={{ fontWeight: "500", color: "var(--primary-green)" }}
              >
                <Box flex="1" textAlign="left">
                  Primary Filters
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <FItem>
                <FormControl as="fieldset">
                  <FormLabel className="f-lable">Project ID</FormLabel>
                  <Input
                    allowClear
                    value={formData.projectID || ""}
                    placeholder="Enter Project ID..."
                    name="projectID"
                    onChange={handleSearchForm}
                  />
                </FormControl>
              </FItem>
              <FItem>
                <span className="f-check-group">
                  <FormControl as="fieldset" className="f-input-group">
                    <FormLabel className="f-lable">Width</FormLabel>
                    <Input
                      allowClear
                      value={formData.width || ""}
                      placeholder="Enter Width..."
                      name="width"
                      onChange={handleSearchForm}
                    />
                  </FormControl>
                  <FormControl as="fieldset" className="f-input-group">
                    <FormLabel className="f-lable">Depth</FormLabel>
                    <Input
                      allowClear
                      value={formData.depth || ""}
                      placeholder="Enter Depth..."
                      name="depth"
                      onChange={handleSearchForm}
                    />
                  </FormControl>
                </span>
              </FItem>
              <FItem>
                <span className="f-check-group">
                  <FormControl as="fieldset" className="f-input-group">
                    <FormLabel className="f-lable">Category</FormLabel>
                    <Input
                      allowClear
                      value={formData.category || ""}
                      placeholder="Enter Category..."
                      name="category"
                      onChange={handleSearchForm}
                    />
                  </FormControl>

                  <FormControl as="fieldset" className="f-input-group">
                    <FormLabel className="f-lable">Subcategory</FormLabel>
                    <Input
                      allowClear
                      value={formData.subcategory || ""}
                      placeholder="Enter Subcategory..."
                      name="subcategory"
                      onChange={handleSearchForm}
                    />
                  </FormControl>
                </span>
              </FItem>
              <FItem>
                <FormControl as="fieldset" className="p-check-group">
                  <FormLabel className="f-lable">Image Type</FormLabel>
                  <RadioGroup
                    name="imagetype"
                    onChange={(e) => {
                      handleSearchForm({
                        target: { name: "imagetype", value: e },
                      });
                    }}
                    value={formData.imagetype}
                  >
                    <span
                      className="f-radio-button"
                      style={{ margin: "0 0.5rem" }}
                    >
                      <Radio value="2D">2D</Radio>
                    </span>
                    <span
                      className="f-radio-button"
                      style={{ margin: "0 0.5rem" }}
                    >
                      <Radio value="3D">3D</Radio>
                    </span>
                    <span
                      className="f-radio-button"
                      style={{ margin: "0 0.5rem" }}
                    >
                      <Radio value="any">Any</Radio>
                    </span>
                  </RadioGroup>
                </FormControl>
              </FItem>
              <FItem>
                <FormControl as="fieldset">
                  <FormLabel className="f-lable">Project Type</FormLabel>
                  <RadioGroup
                    name="projectType"
                    onChange={(e) => {
                      handleSearchForm({
                        target: { name: "projectType", value: e },
                      });
                    }}
                  >
                    <span
                      className="f-radio-button"
                      style={{ margin: "0 0.5rem" }}
                    >
                      {" "}
                      <Radio value="RESIDENTIAL">Residential</Radio>
                    </span>
                    <span
                      className="f-radio-button"
                      style={{ margin: "0 0.5rem" }}
                    >
                      <Radio value="COMMERCIAL">Commercial</Radio>
                    </span>
                  </RadioGroup>
                </FormControl>
              </FItem>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </FDiv>
    </>
  );
}
