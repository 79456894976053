import { Spin, Button, Pagination, Empty } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../Redux";
import LocalCatlogFilter from "./LocalCatlogFilter";
import imagePlaceholder from "./../../Assets/Images/placeholder.png";
import {
  FilterDiv,
  ImageDiv,
  LHeading,
  ListDiv,
  ListFooter,
  MainContainerDiv,
  ModalDiv,
  PImageDiv,
  PInfoDiv,
  PLargeImageDiv,
  PList,
  ProjectDiv,
  ProjectSlider,
  PSliderImageDiv,
} from "./projectCatlogStyle";
import { BaseUrl } from "../../chatapp/apis/Networks";
import Slider from "react-slick";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
} from "@chakra-ui/react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactImageZoom from "react-image-zoom";
import { MobileStepper, Paper, Typography, useTheme } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import PreviewSlider from "./PreviewSlider";
import { green } from "@material-ui/core/colors";
export default function LocalProjectCatlogList() {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [dataList, SetDataList] = useState([]);
  const [sliderData, setSliderData] = useState([]);
  const [searchData, setSearchData] = useState(false);
  const [formData, setFormData] = useState({});
  const [isProjectId, setProjectId] = useState(false);
  const [clearFilter, setFilterClear] = useState(0);
  const { masterProjects } = bindActionCreators(actionCreators, dispatch);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const theme = useTheme();
  const [imageList, setImageList] = useState([]);
  const [previewImageUrls, setPreviewImageUrls] = useState([]);
  const params = new URLSearchParams(window.location.search);
  const history = useHistory();

  const [current, setCurrent] = useState({
    page: 1,
    size: 10,
  });
  const directions = [
    { value: "EE", label: "East " },
    { value: "WW", label: "West" },
    { value: "NN", label: "North" },
    { value: "SS", label: "South" },
    { value: "NE", label: "North-East" },
    { value: "NW", label: "North-West" },
    { value: "SE", label: "South-East" },
    { value: "SW", label: "South-West" },
  ];
  // useEffect(() => {
  //   const fetchAllImageUrls = async () => {
  //     const urls = await Promise.all(
  //       dataList.map(async (data) => {
  //         if (data.projectPreviews.length > 0) {
  //           return getImageUrl(data.projectPreviews[0].filePath);
  //         } else {
  //           return imagePlaceholder;
  //         }
  //       })
  //     );
  //     setPreviewImageUrls(urls);
  //   };
  //   fetchAllImageUrls();
  // }, [dataList]);
  var settings = {
    arrows: true,
    dots: true,
    infinite: true,
    // speed: 500,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const handlePreviewClick = (images) => {
    setImageList(images);
    onOpen();
  };
  useEffect(() => {
    if (state.masterProjects.projects !== null) {
      SetDataList(state.masterProjects.projects);
      if (state.masterProjects?.projects?.length > 0) {
        // state.masterProjects.projects.map((data, I) => {
        setSliderData((prev) => {
          prev = [];
          state.masterProjects.projects.map((d, Index) => {
            prev.push({
              index: 0,
              currentUrl: d?.images[0]?.url,
            });
          });
          return prev;
        });
      }
      // console.log(data)
      // })
      console.log(state.masterProjects?.projects[0]?.images?.length);
    }
  }, [state]);
  // const getImageUrl = async (filePath) => {
  //   const token = localStorage.getItem("token");
  //   try {
  //     const response = await axios.get(
  //       `${BaseUrl}/internal/storage/${filePath}`,
  //       {
  //         responseType: "blob",
  //         headers: {
  //           "X-Api-Key": token,
  //         },
  //       }
  //     );
  //     const imageUrl = URL.createObjectURL(response.data);
  //     console.log("imageUrl", imageUrl);
  //     return imageUrl;
  //   } catch (error) {
  //     console.error("Error fetching image:", error);
  //     return imagePlaceholder;
  //   }
  // };
  useEffect(() => {
    let querry = "";
    let isdays = false;
    Object.entries(formData).map(([key, value], formDataIndex) => {
      querry = querry + `&${key}=${value}`;
      isdays = key == 'days'?true:false;
    });

    console.log("current---", current);
    let offset = current.page === 1 ? 0 : Number(current.page) * 10 - 10;

    if (Object.keys(formData).length > 0) {
      if (clearFilter == 1) {
        if (params.get("projectID")) {
          params.delete("projectID");
          history.push({ search: params.toString() });
          window.location.reload();
        } else {
          masterProjects(`limit=10&offset=0&page=1&days=30`);
        }
        setFilterClear(0);
      } else {
        let url = isdays
          ? `limit=${current.size}&offset=${offset}${querry}&page=${current.page}`
          : `limit=${current.size}&offset=${offset}&days=30${querry}&page=${current.page}`;
        masterProjects(url);
        setSearchData(false);
      }
    } else if (current.page > 1) {
 let url = isdays
   ? `limit=${current.size}&offset=${offset}${querry}&page=${current.page}`
   : `limit=${current.size}&offset=${offset}&days=30${querry}&page=${current.page}`;
         masterProjects(url);
    }
  }, [current, clearFilter]);

  // useEffect(() => {
  //   if (params.get(`projectID`)) {
  //     masterProjects(
  //       `projectID=${params.get(`projectID`)}&limit=10&offset=0&page=1`
  //     );
  //   }
  // }, []);
  useEffect(() => {
    setTimeout(() => {
      if (
        state.masterProjects.loading == false &&
        Object.keys(formData).length == 0 &&
        !searchData &&
        !params.get(`projectID`)
      ) {
        // if (state.masterProjects.projects == null) {
        console.log("inside......2");
        masterProjects(`limit=10&offset=0&page=1&days=30`);
        // }
      }
    }, 500);
  }, []);

  const callRecords = (event) => {
    console.log(event);
    setCurrent((prev) => {
      prev = JSON.parse(JSON.stringify(prev));
      prev.page = prev.size === event.payload.size ? event.payload.page : 1;
      prev.size = event.payload.size;
      return prev;
    });
  };
  return (
    <>
      {state?.masterProjects?.msg !== "" ? (
        <>
          <div
            style={{
              fontSize: "large",
              display: "flex",
              justifyContent: "center",
              fontWeight: "600",
            }}
          >
            <h3>Unauthorized</h3>
          </div>
        </>
      ) : (
        <MainContainerDiv>
          <>
            <FilterDiv>
              <LocalCatlogFilter
                setFilterData={setFormData}
                setFilterClear={setFilterClear}
                setCurrent={setCurrent}
                setSearchData={setSearchData}
                isProjectId={isProjectId}
                clearFilter={clearFilter}
              />
            </FilterDiv>
            <ListDiv>
              <LHeading>MMH Project Listing</LHeading>
              {state.masterProjects?.loading ? (
                <>
                  <div
                    className="ap-flex ap-w100 ap-flex-center-center"
                    style={{ height: "300px" }}
                  >
                    <Spin tip="Fetching Records....." />
                  </div>
                </>
              ) : (
                <>
                  {dataList.length > 0 ? (
                    <>
                      <PList>
                        {dataList.map((data, index) => {
                          // let enlargeImage =data?.previewImage
                          // ? `${BaseUrl}/internal/storage/1024/${data?.previewImage}` || imagePlaceholder : imagePlaceholder;

                          let enlargeImage = imagePlaceholder;
                          let MyCollectionKey = index;
                          let MyCollection = data?.images;
                          let CureentSlideData = sliderData[index];
                          let ActiveStep = sliderData[index].index;
                          const goToNextPicture = () => {
                            ActiveStep = ActiveStep + 1;

                            console.log(
                              ActiveStep,
                              "---",
                              MyCollection[ActiveStep]?.url
                            );
                            setSliderData((prev) => {
                              prev = JSON.parse(JSON.stringify(prev));
                              prev[MyCollectionKey] = {
                                index: ActiveStep,
                                currentUrl: MyCollection[ActiveStep]?.url,
                              };
                              return prev;
                            });
                          };
                          return (
                            <ProjectDiv key={index}>
                              {data?.projectPreviews.length > 0 ? (
                                <>
                                  {data?.projectPreviews[0] ? (
                                    <span
                                      onClick={() =>
                                        handlePreviewClick(data.projectPreviews)
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img
                                        // src={data.projectPreviews[index]?.url}
                                        src={`${BaseUrl}/internal/preview/rimage/500/${data?.projectPreviews[0]?.filePath}`}
                                        style={{
                                          width: "300px",
                                          objectFit: "cover",
                                          minHeight: "125px",
                                        }}
                                        alt=""
                                      />
                                    </span>
                                  ) : (
                                    <div>Loading...</div>
                                  )}
                                  {/* <img
                                    // src={`${BaseUrl}/internal/storage/1024/${data?.projectPreviews[0]?.filePath}`}
                                    src={getImageUrl(
                                      data?.projectPreviews[0]?.filePath
                                    )}
                                    // onError={({ currentTarget }) => {
                                    //   currentTarget.onerror = null; // prevents looping
                                    //   currentTarget.src = imagePlaceholder;
                                    // }}
                                    style={{ minWidth: "300px" }}
                                    alt=""
                                  /> */}
                                </>
                              ) : (
                                <PImageDiv>
                                  <img
                                    src={enlargeImage}
                                    style={{
                                      width: "300px",
                                      objectFit: "cover",
                                      minHeight: "125px",
                                    }}
                                    alt=""
                                  />
                                </PImageDiv>
                              )}

                              {/* </PImageDiv> */}

                              <PInfoDiv>
                                {data?.projectName && (
                                  <span className="p-name">
                                    {data?.projectName}
                                  </span>
                                )}
                                <span className="p-id">
                                  Project id: {data?.projectID}
                                </span>
                                <span className="p-basic-details">
                                  <span
                                    style={{ width: "100%", display: "flex" }}
                                  >
                                    <span style={{ width: "60%" }}>
                                      <span>Width:</span>{" "}
                                      {data?.width ? data?.width + "FT" : "-"}{" "}
                                    </span>
                                    <span style={{ width: "40%" }}>
                                      <span>Depth:</span>{" "}
                                      {data?.depth ? data?.depth + "FT" : "-"}
                                    </span>
                                  </span>
                                  <span
                                    style={{ width: "100%", display: "flex" }}
                                  >
                                    <span style={{ width: "60%" }}>
                                      <span>PlotArea:</span>{" "}
                                      {data?.builtUpArea
                                        ? data?.builtUpArea + "SqFT"
                                        : "-"}{" "}
                                    </span>
                                    <span style={{ width: "40%" }}>
                                      <span>Floor:</span> {data?.floor || "-"}{" "}
                                    </span>
                                  </span>
                                  {/* <span style={{width:"100%",display:"flex"}}> */}
                                  <span>
                                    <span>Direction:</span>
                                    {data?.direction
                                      ? directions.map((d, index) => {
                                          let direction;
                                          if (data?.direction == d.value) {
                                            direction = d?.label;
                                          }
                                          return direction;
                                        })
                                      : "-"}
                                  </span>
                                  <span>
                                    <span>Stair Case Type:</span>{" "}
                                    {data?.staircaseType
                                      ? data?.staircaseType == 1
                                        ? "Internal"
                                        : "External"
                                      : "-"}
                                  </span>
                                </span>
                                {/* </span> */}
                                <span className="p-buttons">
                                  <Button
                                    type="primary"
                                    className="ap-btn-c"
                                    size="middle"
                                    disabled={!data?.folderLink}
                                    onClick={() => {
                                      window.open(data?.folderLink, "_blank");
                                    }}
                                  >
                                    <span className="ap-fesm ap-fwmd">
                                      Folder Link{" "}
                                    </span>
                                  </Button>
                                  <Button
                                    type="primary"
                                    className="ap-btn-c"
                                    size="middle"
                                    // disabled={!data?.trelloLink}
                                    onClick={() => {
                                      history.push(
                                        "/home/ap/trello/details/" +
                                          data?.projectID
                                      );
                                    }}
                                  >
                                    <span className="ap-fesm ap-fwmd">
                                      Go to Project
                                    </span>
                                  </Button>
                                </span>
                              </PInfoDiv>
                            </ProjectDiv>
                          );
                        })}
                      </PList>
                      <ListFooter>
                        <Pagination
                          defaultCurrent={current.page}
                          defaultPageSize={10}
                          total={state?.masterProjects?.totalRecords}
                          onChange={(data, size) => {
                            // console.log("pagination change ", data)
                            callRecords({
                              type: "page",
                              payload: { page: data, size: size },
                            });
                          }}
                          pageSize={current.size}
                          showTotal={(total, range) =>
                            `${range[0]}-${range[1]} of ${total} items`
                          }
                        />
                      </ListFooter>{" "}
                    </>
                  ) : (
                    <div style={{ margin: "20% 0" }}>
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                  )}
                </>
              )}
            </ListDiv>
          </>
          <Modal isOpen={isOpen} onClose={onClose} size="full">
            <ModalOverlay />
            <ModalContent style={{ margin: "1rem", width: "96%" }}>
              <ModalCloseButton fontSize={"2rem"} backgroundColor={green} />
              <ModalBody>
                <div
                  style={{
                    height: "95vh",
                    padding: "15px 15px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <PSliderImageDiv>
                    {/* <Slider {...settings}>
                      {imageList?.map((item) => (
                        <span className="outer-img-div" key={item.url}>
                          <img
                            src={item?.url}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = imagePlaceholder;
                            }}
                            alt=""
                          />
                        </span>
                      ))}
                    </Slider> */}
                    <PreviewSlider imageList={imageList} BaseUrl={BaseUrl} />
                  </PSliderImageDiv>
                </div>
              </ModalBody>
            </ModalContent>
          </Modal>
        </MainContainerDiv>
      )}
    </>
  );
}
