import { Spin, Button, Pagination, Empty } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../Redux";
import imagePlaceholder from "./../../Assets/Images/placeholder.png";
import {
  FilterDiv,
  LHeading,
  ListDiv,
  ListFooter,
  MainContainerDiv,
  PImageDiv,
  PInfoDiv,
  PList,
  ProjectDiv,
  PSliderImageDiv,
} from "./interioriDesignListingStyle";
import { BaseUrl } from "../../chatapp/apis/Networks";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useHistory } from "react-router-dom";
import PreviewSlider from "./PreviewSlider";
import { green } from "@material-ui/core/colors";
import InterioriDesignFilter from "./InterioriDesignFilter";
export default function InterioriDesignList() {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [dataList, SetDataList] = useState([]);
  const [sliderData, setSliderData] = useState([]);
  const [searchData, setSearchData] = useState(false);
  const [formData, setFormData] = useState({});
  const [isProjectId, setProjectId] = useState(false);
  const [clearFilter, setFilterClear] = useState(0);
  const { interiorDesignList } = bindActionCreators(actionCreators, dispatch);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [imageList, setImageList] = useState([]);
  const params = new URLSearchParams(window.location.search);
  const history = useHistory();

  const [current, setCurrent] = useState({
    page: 1,
    size: 10,
  });

useEffect(() => {
  if (state.interiorDesignList?.data !== null) {
    const filteredData = state.interiorDesignList?.data.map((item) => {
      // Extract image file paths from projects
      const images = item.projects.map((project) => project.filePath);

      // Return an object with _id and images array
      return {
        _id: item._id,
        images: images,

        depth: item?.projects[0]?.depth || 0,

        width: item?.projects[0]?.width || 0,
        projectID: item?.projects[0]?.projectID || "N/A",
        projectType: item?.projects[0]?.projectType || "N/A",
        mediaType: item?.projects[0]?.mediaType || "N/A",
        status: item?.projects[0]?.status || "N/A",
      };
    });

    // Set the filtered data to the state
    SetDataList(filteredData);

  
  }
}, [state]);
useEffect(() => {
 console.log("datalist",dataList)
}, [dataList])




  const handlePreviewClick = (images) => {
    setImageList(images);
    onOpen();
  };
  useEffect(() => {
    let querry = "";
    Object.entries(formData).map(([key, value], formDataIndex) => {
      querry = querry + `&${key}=${value}`;
    });

    console.log("current---", current);
    let offset = current.page === 1 ? 0 : Number(current.page) * 10 - 10;

    if (Object.keys(formData).length > 0) {
      if (clearFilter == 1) {
        if (params.get("projectID")) {
          params.delete("projectID");
          history.push({ search: params.toString() });
          window.location.reload();
        } else {
                    window.location.reload();

          interiorDesignList(`limit=10&offset=0&page=1`);
        }
        setFilterClear(0);
      }
    } else if (current.page > 1) {
      let url =  `limit=${current.size}&offset=${offset}&${querry}&page=${current.page}`;
      interiorDesignList(url);
    }
  }, [current, clearFilter]);

  
  useEffect(() => {
    setTimeout(() => {
      if (
        state.interiorDesignList.loading == false &&
        Object.keys(formData).length == 0 &&
        !searchData &&
        !params.get(`projectID`)
      ) {
        // if (state.interiorDesignList.projects == null) {
        console.log("inside......2");
        interiorDesignList(`limit=10&offset=0&page=1`);
        // }
      }
    }, 500);
  }, []);

  useEffect(() => {
    let querry = "";
    let isdays = false;
    Object.entries(formData).map(([key, value], formDataIndex) => {
      querry = querry + `&${key}=${value}`;
      isdays = key == "days" ? true : false;
    });

    console.log("current---", current);
    let offset = current.page === 1 ? 0 : Number(current.page) * 10 - 10;

    if (Object.keys(formData).length > 0) {
      if (clearFilter == 1) {
        if (params.get("designID")) {
          params.delete("designID");
          history.push({ search: params.toString() });
          window.location.reload();
        }
        setFilterClear(0);
      } else {
        let url = isdays
          ? `limit=${current.size}&offset=${offset}${querry}&page=${current.page}`
          : `limit=${current.size}&offset=${offset}&days=30${querry}&page=${current.page}`;
        setSearchData(false);
      }
    } else if (current.page > 1) {
      let url = isdays
        ? `limit=${current.size}&offset=${offset}${querry}&page=${current.page}`
        : `limit=${current.size}&offset=${offset}&days=30${querry}&page=${current.page}`;
    }
  }, [current, clearFilter]);

  


  const callRecords = (event) => {
    console.log(event);
    setCurrent((prev) => {
      prev = JSON.parse(JSON.stringify(prev));
      prev.page = prev.size === event.payload.size ? event.payload.page : 1;
      prev.size = event.payload.size;
      return prev;
    });
  };
  return (
    <>
      <MainContainerDiv>
        <>
          <FilterDiv>
            <InterioriDesignFilter
              setFilterData={setFormData}
              setFilterClear={setFilterClear}
              setCurrent={setCurrent}
              setSearchData={setSearchData}
              isProjectId={isProjectId}
              clearFilter={clearFilter}
              interiorDesignList={interiorDesignList}
            />
          </FilterDiv>
          <ListDiv>
            <LHeading>Interior Design Listing</LHeading>
            {state.interiorDesignList?.loading ? (
              <>
                <div
                  className="ap-flex ap-w100 ap-flex-center-center"
                  style={{ height: "300px" }}
                >
                  <Spin tip="Fetching Records....." />
                </div>
              </>
            ) : (
              <>
                {dataList?.length > 0 ? (
                  <>
                    <PList>
                      {dataList?.map((data, index) => {
                        // let enlargeImage =data?.previewImage
                        // ? `${BaseUrl}/internal/storage/1024/${data?.previewImage}` || imagePlaceholder : imagePlaceholder;

                        let enlargeImage = imagePlaceholder;

                        return (
                          <ProjectDiv key={index}>
                            {data?.images?.length > 0 ? (
                              <>
                                {data?.images[0] ? (
                                  <span
                                    onClick={() =>
                                      handlePreviewClick(data.images)
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <img
                                      src={`${BaseUrl}/internal/preview/rimage/500/${data?.images[0]}`}
                                      // src={`${BaseUrl}internal/storage/1024/${data?.images[0]}`}
                                      style={{
                                        width: "300px",
                                        objectFit: "cover",
                                        minHeight: "205px",
                                      }}
                                      alt=""
                                    />
                                  </span>
                                ) : (
                                  <div>Loading...</div>
                                )}
                              </>
                            ) : (
                              <PImageDiv>
                                <img
                                  src={enlargeImage}
                                  style={{
                                    width: "300px",
                                    objectFit: "cover",
                                    minHeight: "125px",
                                  }}
                                  alt=""
                                />
                              </PImageDiv>
                            )}

                            {/* </PImageDiv> */}

                            <PInfoDiv>
                              {data?.originalName && (
                                <span className="p-name">
                                  {data?.originalName}
                                </span>
                              )}
                              <span className="p-id">
                                Project ID: {data?.projectID}
                              </span>
                              <span className="p-basic-details">
                                <span
                                  style={{ width: "100%", display: "flex" }}
                                >
                                  <span style={{ width: "60%" }}>
                                    <span>Width:</span>{" "}
                                    {data?.width ? data?.width + "FT" : "-"}{" "}
                                  </span>
                                  <span style={{ width: "40%" }}>
                                    <span>Depth:</span>{" "}
                                    {data?.depth ? data?.depth + "FT" : "-"}
                                  </span>
                                </span>
                              </span>
                              {/* </span> */}
                              <span className="p-buttons">
                                {data?.projectID &&
                                  data?.projectID !== "N/A" && (
                                    <Button
                                      type="primary"
                                      className="ap-btn-c"
                                      size="middle"
                                      // disabled={!data?.trelloLink}
                                      onClick={() => {
                                        history.push(
                                          "/home/ap/trello/details/" +
                                            data?.projectID
                                        );
                                      }}
                                    >
                                      <span className="ap-fesm ap-fwmd">
                                        Go to Project
                                      </span>
                                    </Button>
                                  )}
                              </span>
                            </PInfoDiv>
                          </ProjectDiv>
                        );
                      })}
                    </PList>
                    <ListFooter>
                      <Pagination
                        defaultCurrent={current.page}
                        defaultPageSize={10}
                        total={state?.interiorDesignList?.totalRecords}
                        onChange={(data, size) => {
                          // console.log("pagination change ", data)
                          callRecords({
                            type: "page",
                            payload: { page: data, size: size },
                          });
                        }}
                        pageSize={current.size}
                        showTotal={(total, range) =>
                          `${range[0]}-${range[1]} of ${total} items`
                        }
                      />
                    </ListFooter>{" "}
                  </>
                ) : (
                  <div style={{ margin: "20% 0" }}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </div>
                )}
              </>
            )}
          </ListDiv>
        </>
        <Modal isOpen={isOpen} onClose={onClose} size="full">
          <ModalOverlay />
          <ModalContent style={{ margin: "1rem", width: "96%" }}>
            <ModalCloseButton fontSize={"2rem"} backgroundColor={green} />
            <ModalBody>
              <div
                style={{
                  height: "95vh",
                  padding: "15px 15px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PSliderImageDiv>
                  <PreviewSlider imageList={imageList} BaseUrl={BaseUrl} />
                </PSliderImageDiv>
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      </MainContainerDiv>
    </>
  );
}
